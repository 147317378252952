import { inject, Injectable } from '@angular/core';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';

import {
    BillingItemDto,
    BillingItemType,
    BillingItemWithEffectiveRate,
    ChartOfAccountsResource,
    ExtendedBilingItemDto,
    Page,
    ProductsServicesResource,
    RateTierDto,
} from '@portal/shared/utils';

@Injectable()
export class ProductsServicesResourceController {
    private readonly billingItemsResource = inject(ProductsServicesResource);
    private readonly chartOfAccountsResource = inject(ChartOfAccountsResource);

    public getBillingItems(config: { billingItemType?: BillingItemType; page?: number; size?: number }): Observable<Page<BillingItemDto>> {
        return this.billingItemsResource.getBillingItems(config);
    }

    public getBillingItem(itemId: number): Observable<ExtendedBilingItemDto> {
        return this.billingItemsResource.getBillingItem(itemId).pipe(
            switchMap((item) => {
                const expenseAccount$ = item.expenseAccountId
                    ? this.chartOfAccountsResource.getLedgerAccount(item.expenseAccountId!)
                    : of(undefined);

                const incomeAccount$ = item.incomeAccountId
                    ? this.chartOfAccountsResource.getLedgerAccount(item.incomeAccountId)
                    : of(undefined);

                return forkJoin({
                    expenseAccount: expenseAccount$,
                    incomeAccount: incomeAccount$,
                }).pipe(
                    map(({ expenseAccount, incomeAccount }) => ({
                        ...item,
                        expenseAccount,
                        incomeAccount,
                    }))
                );
            })
        );
    }

    public getBillingItemsWithEffectiveRates(): Observable<BillingItemWithEffectiveRate[]> {
        return this.billingItemsResource.getBillingItemsWithEffectiveRates();
    }

    public saveRateTier(config: { billingItemId: number; rateTier: RateTierDto }): Observable<RateTierDto> {
        return this.billingItemsResource.saveRateTier(config);
    }

    public createServiceBillingItem(billingItem: BillingItemDto): Observable<BillingItemDto> {
        return this.billingItemsResource.createServiceBillingItem(billingItem);
    }

    public createInventoryBillingItem(billingItem: BillingItemDto): Observable<BillingItemDto> {
        return this.billingItemsResource.createInventoryBillingItem(billingItem);
    }

    public deleteBillingItem(itemId: number): Observable<string> {
        return this.billingItemsResource.deleteBillingItem(itemId);
    }

    public updateRateTier(config: { billingItemId: number; rateTierId: number; rateTier: RateTierDto }): Observable<RateTierDto> {
        return this.billingItemsResource.updateRateTier(config);
    }

    public updateServiceBillingItem(config: { billingItemId: number; billingItem: BillingItemDto }): Observable<BillingItemDto> {
        return this.billingItemsResource.updateServiceBillingItem(config);
    }

    public updateInventoryBillingItem(config: { billingItemId: number; billingItem: BillingItemDto }): Observable<BillingItemDto> {
        return this.billingItemsResource.updateInventoryBillingItem(config);
    }
}
