import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ContactDto, ContactResource, ContactType, GetContactsParams, Page } from '@portal/shared/utils';

@Injectable()
export class ContactResourceController {
    readonly #resource: ContactResource = inject(ContactResource);

    public getContacts(params?: GetContactsParams): Observable<Page<ContactDto>> {
        return this.#resource.getContacts(params);
    }

    public getContactsByType(params?: {
        searchText?: string;
        contactType: ContactType;
        page: number;
        size: number;
    }): Observable<Page<ContactDto>> {
        return this.#resource.getContactsByType(params);
    }

    public getContactsByGroup(groupId: number, params?: GetContactsParams): Observable<Page<ContactDto>> {
        return this.#resource.getContactsByGroup(groupId, params);
    }
}
