import { Provider } from '@angular/core';

import { AuthenticationResource, ContactResource, SettingsResource, UsersResource } from '@portal/shared/utils';

import { ContactResourceController } from './contact-api';
import { SettingsResourceController } from './location-api';
import { AuthenticationResourceController, UsersResourceController } from './user-api';

export const SharedDataAccessApiProviders: Provider = [
    UsersResource,
    UsersResourceController,
    AuthenticationResource,
    AuthenticationResourceController,
    SettingsResource,
    SettingsResourceController,
    ContactResource,
    ContactResourceController,
];
