import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LoginDto, UserDto, UsersResource } from '@portal/shared/utils';

@Injectable()
export class UsersResourceController {
    private readonly _resource: UsersResource = inject(UsersResource);

    public changePassword(loginDto: LoginDto): Observable<UserDto> {
        return this._resource.changePassword(loginDto);
    }
}
