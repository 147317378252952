import { HttpErrorResponse } from '@angular/common/http';
import { computed, inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { concatMap, exhaustMap, merge, pipe, Subject, takeUntil, tap } from 'rxjs';

import { DatePipeUtils, LocalizationSource, LocalizationUtils, SettingsType, withCrudEvents } from '@portal/shared/utils';

import { SettingsResourceController } from '../../../+api';

interface State {
    settings: LocalizationUtils.Settings | null;
}

const initialState: State = {
    settings: null,
};

export const LocalizationStore = signalStore(
    {},
    withState<State>(initialState),
    withComputed((state) => ({
        datePipeFormat: computed(() => DatePipeUtils.getDatePipeFormat(state.settings()?.dateFormat ?? null)),
    })),
    withCrudEvents<LocalizationUtils.Settings>(),
    withMethods(
        (store, settingsController: SettingsResourceController<LocalizationUtils.Settings> = inject(SettingsResourceController)) => ({
            read: rxMethod<void>(
                pipe(
                    tap(() => patchState(store, { readingEntity: true })),
                    exhaustMap(() =>
                        settingsController.getSettings(SettingsType.LOCALIZATION).pipe(
                            tapResponse({
                                next: (settings: LocalizationUtils.Settings) => {
                                    store._readEntitySuccess(settings);
                                    patchState(store, { settings });
                                },
                                error: (e: HttpErrorResponse) => {
                                    store._readEntityFailed(e.error?.message ?? null);
                                },
                                finalize: () => patchState(store, { readingEntity: false }),
                            })
                        )
                    )
                )
            ),
            update: rxMethod<LocalizationUtils.Settings>(
                pipe(
                    tap(() => patchState(store, { updatingEntity: true })),
                    concatMap((settings) =>
                        settingsController.updateSettings(SettingsType.LOCALIZATION, settings).pipe(
                            tapResponse({
                                next: () => {
                                    store._updateEntitySuccess(settings);
                                    patchState(store, { settings });
                                },
                                error: (e: HttpErrorResponse) => {
                                    store._updateEntityFailed(e.error?.message ?? null);
                                },
                                finalize: () => patchState(store, { updatingEntity: false }),
                            })
                        )
                    )
                )
            ),
            onRead: rxMethod<void>(pipe()),
        })
    ),
    withMethods((store) => ({
        initialize: () => {
            store.read();
        },
    })),
    withHooks((state, destroy = new Subject<void>(), localizationSource = inject(LocalizationSource)) => ({
        onInit: () => {
            merge(state.onReadEntitySuccess(), state.onUpdateEntitySuccess())
                .pipe(takeUntil(destroy))
                .subscribe((settings: LocalizationUtils.Settings) => {
                    if (settings.dateFormat) {
                        localizationSource.setDateFormat(settings.dateFormat);
                    }
                    if (settings.country) {
                        localizationSource.setCountry(settings.country);
                    }
                });
        },
        onDestroy: () => {
            destroy.next();
            destroy.complete();
        },
    }))
);
